/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/SignatureModal.vue';
import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-18c8b9ce"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
var _hoisted_2 = {
  key: 1
};
import { Upload } from "@/js/services/baseService";
export default {
  __name: 'signature-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    config: {
      type: Object,
      default: function _default() {
        return {
          x: "0%",
          y: "0%",
          width: "100%",
          height: "100%"
        };
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var instance = getCurrentInstance();
    var keyValue = computed(function () {
      var _instance$vnode;

      return instance === null || instance === void 0 ? void 0 : (_instance$vnode = instance.vnode) === null || _instance$vnode === void 0 ? void 0 : _instance$vnode.key;
    });
    var state = reactive({
      visible: false
    });

    function onConfirm(e) {
      var now = Date.now();
      var signData = "electronic-operate-record/".concat(keyValue.value, "/").concat(now, ".png");
      Upload.base64({
        path: signData,
        file_name: "".concat(now, ".png"),
        base64: e
      }).then(function (data) {
        state.visible = false;
        emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
          sign: data.file_url
        }));
      });
    }

    return function (_ctx, _cache) {
      var _component_SignatureModal = __unplugin_components_0;

      return _openBlock(), _createElementBlock("div", {
        style: _normalizeStyle({
          left: __props.config.x,
          top: __props.config.y,
          width: __props.config.width,
          height: __props.config.height
        }),
        class: "sign-block",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _unref(state).visible = true;
        })
      }, [__props.value.sign ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "sign-preview",
        src: __props.value.sign,
        alt: ""
      }, null, 8, _hoisted_1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, "簽名欄位")), _createVNode(_component_SignatureModal, {
        visible: _unref(state).visible,
        onConfirm: onConfirm
      }, null, 8, ["visible"])], 4);
    };
  }
};