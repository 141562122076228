/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/MFsLightbox.vue';
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1e0be56a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
export default {
  __name: 'image-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    config: {
      type: Object,
      default: function _default() {
        return {
          x: "0%",
          y: "0%",
          width: "100%",
          height: "100%"
        };
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var state = reactive({
      lightboxShow: false,
      medias: []
    });

    function viewMedia(evt) {
      state.medias = [props.value.src];
      state.lightboxShow = true;
    }

    return function (_ctx, _cache) {
      var _component_MFsLightbox = __unplugin_components_0;

      return _openBlock(), _createElementBlock("div", {
        style: _normalizeStyle({
          left: __props.config.x,
          top: __props.config.y,
          width: __props.config.width,
          height: __props.config.height
        }),
        class: "image-block"
      }, [_createElementVNode("img", {
        onClick: viewMedia,
        src: __props.value.src,
        alt: ""
      }, null, 8, _hoisted_1), (_openBlock(), _createBlock(_Teleport, {
        to: "body"
      }, [_createVNode(_component_MFsLightbox, {
        modelValue: _unref(state).lightboxShow,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _unref(state).lightboxShow = $event;
        }),
        sources: _unref(state).medias
      }, null, 8, ["modelValue", "sources"])]))], 4);
    };
  }
};