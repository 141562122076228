/* unplugin-vue-components disabled */import _toConsumableArray from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\toConsumableArray.js";
import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import { useCssVars as _useCssVars, unref as _unref } from 'vue';
import { createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import MSelect from "../MSelect.vue";
export default {
  __name: 'single-select-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    config: {
      type: Object,
      default: function _default() {
        return {
          x: "0%",
          y: "0%",
          width: "100%",
          height: "100%"
        };
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;

    _useCssVars(function (_ctx) {
      return {
        "25956560": _unref(fontSize)
      };
    });

    function updateValue(selected) {
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        selected: selected
      }));
    }

    var selectRef = ref(null);
    var DEFAULT_FONT_SIZE = 14;
    var fontSize = ref(DEFAULT_FONT_SIZE);

    function shrinkFontSize() {
      if (fontSize.value <= 0) return;
      var outer = selectRef.value.querySelector(".m-select__multiple-display");
      var width = outer.clientWidth - 16;
      var childNodes = selectRef.value.querySelectorAll(".m-select__multiple-item");
      var marginWidth = (childNodes.length - 1) * 2;
      var totalWidth = _toConsumableArray(childNodes).reduce(function (acc, cur) {
        return acc + cur.clientWidth;
      }, 0) + marginWidth;

      if (totalWidth > width) {
        fontSize.value -= 1;
        nextTick(function () {
          return shrinkFontSize();
        });
      }
    }

    watch(function () {
      return props.value.selected;
    }, function (val) {
      if (val.length) {
        fontSize.value = DEFAULT_FONT_SIZE;
        nextTick(function () {
          return shrinkFontSize();
        });
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        style: _normalizeStyle({
          left: __props.config.x,
          top: __props.config.y,
          width: __props.config.width,
          height: __props.config.height
        }),
        ref_key: "selectRef",
        ref: selectRef,
        class: "single-select-block"
      }, [_createVNode(MSelect, {
        "show-all": "",
        multiple: __props.value.multiple,
        "model-value": __props.value.selected,
        class: "target-select",
        options: __props.value.options.map(function (i) {
          return {
            value: i.text,
            name: i.text
          };
        }),
        h: "100%",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return updateValue($event);
        })
      }, null, 8, ["multiple", "model-value", "options"])], 4);
    };
  }
};