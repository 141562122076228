/* unplugin-vue-components disabled */import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "m-dropdown-menu"
};
import 'tether-drop/dist/css/drop-theme-basic.min.css';
import Drop from 'tether-drop';
export default {
  __name: 'MDropdownMenu',
  props: {
    classes: String,
    dropConfig: {
      type: Object,
      default: {}
    }
  },
  emits: ['open'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var refTarget = ref(null);
    var refMenu = ref(null);
    var cData = reactive({
      dropInstance: ''
    });
    onMounted(function () {
      var defaultDropConfig = {
        tetherOptions: {
          /** 
           * @see https://github.com/HubSpot/drop/issues/170#issuecomment-286484965
           */
          constraints: [{
            /** @type {'window'|'scrollParent'} */
            to: 'window',
            pin: true
          }] // offset: 'x y'

        }
      };
      var mergedDropConfig = $.extend(true, {}, defaultDropConfig, props.dropConfig);
      var dropInstance = new Drop(_objectSpread({
        target: refTarget.value,
        content: refMenu.value,
        // classes: 'drop-theme-arrows',
        position: 'bottom left',
        openOn: 'click',
        classes: props.classes
      }, mergedDropConfig));
      cData.dropInstance = dropInstance;
      dropInstance.on('open', function () {
        emit('open');
      });
      dropInstance.on('close', function () {});
    });
    onUnmounted(function () {
      if (cData.dropInstance && 'function' === typeof cData.dropInstance.destroy) {
        cData.dropInstance.destroy();
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        ref_key: "refTarget",
        ref: refTarget,
        class: "m-dropdown-menu__default"
      }, [_renderSlot(_ctx.$slots, "default")], 512), _createElementVNode("div", {
        ref_key: "refMenu",
        ref: refMenu,
        class: "m-dropdown-menu__menu"
      }, [_renderSlot(_ctx.$slots, "menu", {
        instance: _unref(cData).dropInstance
      })], 512)]);
    };
  }
};