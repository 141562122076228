import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-43314239"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["innerHTML"];
export default {
  __name: 'customer-tel-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    config: {
      type: Object,
      default: function _default() {
        return {
          x: "0%",
          y: "0%",
          width: "100%",
          height: "100%"
        };
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        style: _normalizeStyle({
          left: __props.config.x,
          top: __props.config.y,
          width: __props.config.width,
          height: __props.config.height
        }),
        class: "customer-tel-block",
        innerHTML: __props.value.text
      }, null, 12, _hoisted_1);
    };
  }
};